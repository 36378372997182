import { setJwt } from "@/api";
import { accountApi } from "@/api/account";
import { parseQuery, query } from "@/utils/api-utils";
import { getOrigin, isOrigin } from "@/utils/origin";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useBillingStore } from "./billing";
import { useBlockListsStore } from "./blocks";
import { useCommentsStore } from "./comments";
import { useDataStore } from "./data";
import { useLogsStore } from "./logs";
import { useRepliesStore } from "./replies";
import { useUiStore } from "./ui";
import { useWordStatsStore } from "./word-stats";

export const useAccountStore = defineStore("account", () => {

  const status = ref<"not-authorized" | "authorized" | "init">("init")
  const currentUser = ref<App.User | null>(null)

  const init = async () => {
    const { jwt: paramsJwt, referals } = parseQuery(window.location.search)
    if (referals && typeof referals === "string") {
      window.localStorage.setItem("cachedReferals", referals)
    }
    if (paramsJwt && typeof paramsJwt === "string") {
      window.localStorage.setItem("reply200-jwt", paramsJwt)
      const router = useRouter()
      router.replace(window.location.pathname)
    }
    const jwt = window.localStorage.getItem("reply200-jwt")
    if (!jwt) {
      status.value = "not-authorized"
      return
    }
    try {
      setJwt(jwt)
      const currentUser = await accountApi.getCurrentUserInfo()
      _authorize(currentUser, jwt)
    } catch (e) {
      setJwt(null)
      status.value = "not-authorized"
    }
  }

  const _authorize = (user: App.User, jwt?: string) => {
    if (jwt && !isOrigin()) {
      const referals = window.localStorage.getItem("cachedReferals") || undefined
      window.localStorage.removeItem("cachedReferals")
      window.location.href = getOrigin()+query({ jwt, referals })
      return
    } 
    if (jwt) {
      setJwt(jwt)
      window.localStorage.setItem("reply200-jwt", jwt)
    }
    currentUser.value = user
    status.value = "authorized"

    activateReferals()
  }

  const login = async ({ login, password }: { login: string, password: string }) => {
    const { jwt, user } = await accountApi.login({ login, password })
    _authorize(user, jwt)
  }

  const forgotPassword = async ({ email }: { email: string }) => {
    const resp = await accountApi.forgotPassword({ email })
  }

  const recoverPassword = async ({ password, password_repeat, token }: { password: string, password_repeat: string, token: string }) => {
    const resp = await accountApi.recoverPassword({ password, password_repeat, token })
  }

  const register = async ({ email, password }: { email: string, password: string }) => {
    const { jwt, user } = await accountApi.register({ email, password })
    _authorize(user, jwt)
  }

  const authByGoogle = async () => {
    const { url } = await accountApi.getGoogleLink(window.origin+"/auth/google")
    window.location.href = url
  }
  const confirmAuthByGoogle = async ({ code, state }: { code: string, state: string }) => {
    const redirect_uri = window.origin+"/auth/google"
    const { jwt, user } = await accountApi.authByGoogle({ code, state, redirect_uri })
    _authorize(user, jwt)
  }
  const connectGoogleAuth = async ({ code, state }: { code: string, state: string }) => {
    const redirect_uri = window.origin+"/auth/google"
    await accountApi.connectGoogleAuth({ code, state, redirect_uri })
    currentUser.value!.networks.push("google")
  } 
  const disconnectGoogleAuth = async () => {
    await accountApi.disconnectGoogleAuth()
    currentUser.value!.networks = currentUser.value!.networks.filter(item => item !== "google")
  }

  const authByFacebook = async () => {
    const { url } = await accountApi.getFacebookLink(window.origin+"/auth/facebook")
    window.location.href = url
  }
  const confirmAuthByFacebook = async ({ code, state }: { code: string, state: string }) => {
    const redirect_uri = window.origin+"/auth/facebook"
    const { jwt, user } = await accountApi.authByFacebook({ code, state, redirect_uri })
    _authorize(user, jwt)
  }
  const connectFacebookAuth = async ({ code, state }: { code: string, state: string }) => {
    const redirect_uri = window.origin+"/auth/facebook"
    await accountApi.connectFacebookAuth({ code, state, redirect_uri })
    currentUser.value!.networks.push("facebook")
  } 
  const disconnectFacebookAuth = async () => {
    await accountApi.disconnectFacebookAuth()
    currentUser.value!.networks = currentUser.value!.networks.filter(item => item !== "facebook")
  }


  const requestCode = async () => {
    await accountApi.requestCode()
  }

  const activateCode = async (code: string) => {
    const { user } = await accountApi.activateCode({ code })
    _authorize(user)
  }

  const logout = () => {
    setJwt(null)
    window.localStorage.removeItem("reply200-jwt")
    currentUser.value = null
    status.value = "not-authorized"
    window.localStorage.removeItem("showWelcome")
    window.localStorage.removeItem("showLimitAlert")
      
    useDataStore().reset()
    useCommentsStore().reset()
    useBlockListsStore().reset()
    useRepliesStore().reset()
    useLogsStore().reset()
    useBillingStore().reset()
    useWordStatsStore().reset()
    useUiStore().reset()
  }

  const changePassword = async (password: string, new_password: string, new_password_repeat: string) => {
    await accountApi.changePassword({ password, new_password, new_password_repeat })
  }

  const changeEmail = async (email: string, password: string) => {
    await accountApi.changeEmail({ email, password })
  }

  const activateReferals = () => {
    if (currentUser.value?.status_id !== 10) return
    const _cachedReferals = window.localStorage.getItem("cachedReferals")
    if (_cachedReferals) {
      window.localStorage.removeItem("cachedReferals")
      if (typeof _cachedReferals === 'string' && _cachedReferals.trim() !== '') {
        applyReferal(_cachedReferals);
      }
      /*for (let code of JSON.parse(_cachedReferals)) {
        applyReferal(code)
      }*/
    }
  }

  const validateReferal = async (code: string) => {
    const { actions } = await accountApi.validateReferal(code)
    window.localStorage.setItem("cachedReferals", code)

    return actions
  }

  /*const validateReferal = async (code: string) => {
    const { actions } = await accountApi.validateReferal(code)
    const _cachedReferals = window.localStorage.getItem("cachedReferals")
    const cachedReferals = _cachedReferals? JSON.parse(_cachedReferals): []

    if (cachedReferals.includes(code)) return actions
    cachedReferals.push(code)
    window.localStorage.setItem("cachedReferals", JSON.stringify(cachedReferals))

    return actions
  }*/

  const billingStore = useBillingStore()
  const applyReferal = async (code: string) => {
    const { actions } = await accountApi.applyReferal(code)
    if (billingStore.details) {
      billingStore.requestDetails()
    }
    return actions
  }

  return { 
    status,
    currentUser, 
    init,
    login, 
    register, 
    forgotPassword,
    recoverPassword,
    authByGoogle, 
    confirmAuthByGoogle,
    connectGoogleAuth,
    disconnectGoogleAuth,
    authByFacebook,
    confirmAuthByFacebook,
    connectFacebookAuth,
    disconnectFacebookAuth,
    requestCode, 
    activateCode,
    logout,
    changePassword,
    changeEmail,
    validateReferal,
    applyReferal
  }
})